var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "360px",
        top: "0",
        "custom-class": "emailPasswordVerificationDialog",
        "destroy-on-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "z-index": "3000"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("span", {
        staticClass: "iconfont_Me icon-x closeIcon",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.close.apply(null, arguments)
          }
        }
      }),
      _vm.confirmIfVerificationIsRequired
        ? [
            _c("div", { staticClass: "dialogTitle" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("tips")) + "\n    ")
            ]),
            _c(
              "div",
              { staticClass: "dialogContent" },
              [
                _c("el-image", {
                  staticStyle: {
                    width: "24px",
                    height: "24px",
                    "margin-right": "8px",
                    "flex-shrink": "0"
                  },
                  attrs: {
                    src: require("../../../../../../assets/images/email/send/tipsIcon.png"),
                    fit: "cover"
                  }
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("confirmIfVerificationIsRequired")) +
                    "\n    "
                )
              ],
              1
            ),
            _c("div", { staticClass: "dialogFooter" }, [
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "buttonStyle",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.startVerification.apply(null, arguments)
                    }
                  }
                },
                [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
              )
            ])
          ]
        : [
            _c("div", { staticClass: "dialogTitle" }, [
              _vm._v("\n      " + _vm._s(_vm.dialogTitle) + "\n    ")
            ]),
            _c("div", { staticClass: "dialogContent" }, [
              _c("div", { staticClass: "inputBox" }, [
                _c("div", { staticClass: "accountInfo" }, [
                  _c(
                    "span",
                    { staticClass: "title", attrs: { title: _vm.userName } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.userName) + "\n          "
                      )
                    ]
                  ),
                  _c(
                    "span",
                    { staticClass: "mailName", attrs: { title: _vm.mailName } },
                    [_vm._v(" (" + _vm._s(_vm.mailName) + ") ")]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c(
                      "CommonInput",
                      {
                        attrs: {
                          passwordInput: true,
                          needInputshowError: _vm.inputError
                        },
                        on: { inputChange: _vm.getPasswordText }
                      },
                      [
                        _c("span", {
                          staticClass:
                            "iconfont_Me icon-password-fill iconStyle"
                        })
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "dialogFooter" }, [
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "buttonStyle",
                  class: _vm.password == "" ? "disable" : "",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.confirm.apply(null, arguments)
                    }
                  }
                },
                [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }