<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="360px"
    :top="'0'"
    custom-class="emailPasswordVerificationDialog"
    :destroy-on-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    z-index="3000"
  >
    <span class="iconfont_Me icon-x closeIcon" @click.stop="close"></span>
    <template v-if="confirmIfVerificationIsRequired">
      <div class="dialogTitle">
        {{ $t("tips") }}
      </div>
      <div class="dialogContent">
        <el-image
          style="width: 24px; height: 24px; margin-right: 8px; flex-shrink: 0"
          :src="
            require('../../../../../../assets/images/email/send/tipsIcon.png')
          "
          :fit="'cover'"
        ></el-image>
        {{ $t("confirmIfVerificationIsRequired") }}
      </div>
      <div class="dialogFooter">
        <div class="buttonStyle" v-waves @click.stop="startVerification">
          <i class="iconfont_Me icon-check iconStyle"></i>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="dialogTitle">
        {{ dialogTitle }}
      </div>
      <div class="dialogContent">
        <div class="inputBox">
          <div class="accountInfo">
            <span class="title" :title="userName">
              {{ userName }}
            </span>
            <span class="mailName" :title="mailName"> ({{ mailName }}) </span>
          </div>
          <div class="input">
            <CommonInput
              @inputChange="getPasswordText"
              :passwordInput="true"
              :needInputshowError="inputError"
            >
              <span class="iconfont_Me icon-password-fill iconStyle"></span>
            </CommonInput>
          </div>
        </div>
      </div>
      <div class="dialogFooter">
        <div
          class="buttonStyle"
          :class="password == '' ? 'disable' : ''"
          v-waves
          @click.stop="confirm"
        >
          <i class="iconfont_Me icon-check iconStyle"></i>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import CommonInput from "@/newVersion/components/inputComponent/commonInput.vue";
import { verifyUserAccountPassword } from "@/newVersion/components/accountSecurityDialog/api";
import { unBindEmail } from "@/api/newVersion/melinked_email";
import { encryptData } from "@/utils/aes_email";
import { deleteEmailInLocalDatabase } from "@/api/indexDB/api/email_api";
import { setSecretKeyByOriginId } from "@/api/indexDB/api/email_secretKey_api";
export default {
  name: "EmailPasswordVerificationDialog",
  components: {
    CommonInput,
  },
  data() {
    return {
      password: "",
      dialogVisible: false,
      inputError: false,
      confirmIfVerificationIsRequired: false,
    };
  },
  computed: {
    emailPasswordVerificationDialog() {
      return this.$store.state.melinked_email.emailPasswordVerificationDialog;
    },
    emailAccounts() {
      return this.$store.state.melinked_email.emailAccounts;
    },
    currentEmailAccount() {
      return this.$store.state.melinked_email.currentEmailAccount;
    },
    currentComponent() {
      return this.$store.state.melinked_email.currentComponent;
    },
    dialogTitle() {
      let title = "";
      if (!this.emailPasswordVerificationDialog) {
        return;
      }
      switch (this.emailPasswordVerificationDialog.type) {
        case "verify":
          title = this.$t("verify_email_password");
          break;
        case "unbind":
          title = this.$t("unbindEmailAccountTitle");
          break;
      }
      return title;
    },
    userName() {
      if (!this.emailPasswordVerificationDialog) {
        return "";
      }
      return (
        this.emailPasswordVerificationDialog.item.contactsName ||
        this.emailPasswordVerificationDialog.item.mailAddress.split("@")[0]
      );
    },
    mailName() {
      if (!this.emailPasswordVerificationDialog) {
        return "";
      }
      return this.emailPasswordVerificationDialog.item.mailAddress;
    },
  },
  mounted() {
    this.dialogVisible = true;
    this.handlerShowView();
  },
  beforeDestroy() {
    this.dialogVisible = false;
  },
  methods: {
    startVerification() {
      this.confirmIfVerificationIsRequired = false;
    },
    handlerShowView() {
      if (this.currentComponent == "send") {
        this.confirmIfVerificationIsRequired = true;
      } else {
        this.confirmIfVerificationIsRequired = false;
      }
    },
    getPasswordText(text) {
      this.password = text;
    },
    async confirm() {
      if (!this.emailPasswordVerificationDialog) {
        return;
      }
      if (this.password) {
        this.$store.commit("setEmailModuleLoading", true);
        let params = {
          checkStrongPassword: false,
          password: this.fnencrypt(this.password),
          userId: this.emailPasswordVerificationDialog.item.originProviderId,
        };
        const result = await verifyUserAccountPassword(params);
        if (result && result.code === 200 && result.data.data) {
          this.inputError = false;
          this.verificationSuccessful();
        } else {
          this.$store.commit("setEmailModuleLoading", false);
          this.$message({
            type: "error",
            message: result.message,
          });
          this.inputError = true;
        }
      }
    },
    // 存入本地缓存
    async setPassword(data) {
      const handlerData = encryptData(data);
      return new Promise(async (resolve, reject) => {
        await setSecretKeyByOriginId(
          this.emailPasswordVerificationDialog.item.originProviderId,
          handlerData
        );
        resolve();
      });
    },
    async verificationSuccessful() {
      if (!this.emailPasswordVerificationDialog) {
        this.$store.commit("setEmailModuleLoading", false);
        return;
      }
      const {
        mailAddress,
        originProviderId,
      } = this.emailPasswordVerificationDialog.item;
      switch (this.emailPasswordVerificationDialog.type) {
        case "verify":
          await this.setPassword(this.password);
          this.$store.commit("setCurrent_list_module_to_enter", {
            emailProviderId: originProviderId,
            pageType: "inbox",
          });
          this.$store.commit("setRefreshEmailSubMenu", true);
          break;
        case "unbind":
          const params = {
            password: this.fnencrypt(this.password),
            mailAddress: mailAddress,
          };
          const result = await unBindEmail(params);
          if (result.code == 200) {
            let emailAccounts = _.cloneDeep(this.emailAccounts);
            emailAccounts = emailAccounts.filter((account) => {
              return account.originProviderId != originProviderId;
            });
            this.$store.commit("setEmailAccounts", emailAccounts);
            if (this.currentEmailAccount.originProviderId == originProviderId) {
              this.$store.commit("setCurrentEmailAccount", null);
            }
            await deleteEmailInLocalDatabase(mailAddress);
            await this.handlerUnreadEmailNum();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          break;
      }
      this.$store.commit("setEmailModuleLoading", false);
      this.close();
    },
    close() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$store.commit("setEmailPasswordVerificationDialog", null);
    },
  },
};
</script>
<style lang="stylus" scoped></style>
<style lang="stylus">
.emailPasswordVerificationDialog
  padding: 0 !important;
  min-height: 320px;
  overflow: hidden;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    width: 100%;
    min-height: 320px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .closeIcon
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 16px;
      color: #858585;
      cursor: pointer;
      z-index: 1;
    .dialogTitle
      width: 100%;
      padding-top: 36px;
      text-align: center;
      font-size: 20px;
      color: #000000;
      font-weight: 500;
      flex-shrink: 0;
    .dialogContent
      width: 100%;
      flex: 1;
      min-height: 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #000000;
      word-break: break-word;
      .inputBox
        width: 100%;
        height: 90px;
        .accountInfo
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 8px;
          text-align: center;
          .title
            color: #292D32;
            font-weight: 700;
            font-size: 18px;
          .mailName
            font-size: 16px;
            color: #858585;
        .input
          width: 100%;
          height: 48px;
          overflow: hidden;
          .iconStyle
            color #666666;
            font-size: 28px;
    .dialogFooter
      width: 100%;
      height: 48px;
      margin-bottom: 24px;
      flex-shrink: 0;
      padding: 0 24px;
      .disable
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
      .buttonStyle
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 4px;
        background: #F39945;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle
          font-size: 24px;
          color: #FFFFFF;
</style>
